export const pcaScript = (n: Window & typeof globalThis, t: Document, i: string | number, r: string) => {
  let u, f;
  (n[i] = n[i] || {}),
    (n[i].initial = {
      accountCode: "UNILE11121",
      host: "UNILE11121.pcapredict.com"
    }),
    (n[i].on =
      n[i].on ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (n[i].onq = n[i].onq || []).push(arguments);
      }),
    (u = t.createElement("script")),
    (u.type = "text/javascript"),
    (u.defer = !0),
    (u.src = r),
    (u.id = "UNILE11121"),
    (f = t.getElementsByTagName("script")[0]);
  f.parentNode.insertBefore(u, f);
};
