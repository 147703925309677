import React, { FunctionComponent, useEffect } from "react";
import loadable from "@loadable/component";
import Section from "../Section";
import "./styles.scss";
import { pcaScript } from "./AddressBook/pcapredict";

const ProfileInfo = loadable(() => import("./ProfileInfo"));
const AddressBook = loadable(() => import("./AddressBook"));
const OrderHistory = loadable(() => import("./OrderHistory"));
const Password = loadable(() => import("./Password"));
const PaymentInfo = loadable(() => import("./PaymentInfo"));
const Subscriptions = loadable(() => import("./Subscriptions"));

export interface ProfileDetailsInterface {
  title?: string;
  description?: string;
  profileDetailsType: string;
}

type ProfileDetailsRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

const ProfileDetailsMap: ProfileDetailsRenderableSanityTypeNameToComponentMap = {
  profileInfo: ProfileInfo,
  password: Password,
  addressBook: AddressBook,
  orderHistory: OrderHistory,
  paymentInfo: PaymentInfo,
  subscriptions: Subscriptions
};

interface ProfileDetailsRendererInterface {
  profileDetailsType: string;
}

const ProfileDetailsRenderer: FunctionComponent<ProfileDetailsRendererInterface> = ({ profileDetailsType }) => {
  useEffect(() => {
    typeof window !== "undefined" &&
      !document.getElementById("UNILE11121") &&
      pcaScript(window, document, "pca", "//UNILE11121.pcapredict.com/js/sensor.min.js");
  }, []);
  const getComponent = (profileDetailsType: string) => {
    const component = ProfileDetailsMap[profileDetailsType];
    if (component) {
      return component;
    } else {
      console.error(`Unknown profile details type: ${profileDetailsType}`);
      return null;
    }
  };

  const Comp = getComponent(profileDetailsType);

  return Comp
    ? React.createElement(Comp, {
        profileDetailsType: profileDetailsType
      })
    : null;
};

const ProfileDetails: FunctionComponent<ProfileDetailsInterface> = ({ title, description, profileDetailsType }) => {
  return (
    <Section
      sectionClassName={`c-profile-details c-profile-details--${profileDetailsType}`}
      title={title}
      description={description}
    >
      <ProfileDetailsRenderer profileDetailsType={profileDetailsType} />
    </Section>
  );
};

export default ProfileDetails;
